/* eslint-disable no-useless-escape */
$(function () {
  $.validator.addMethod("textOnly", function (e, t, i) {
    return /^[a-zA-Z-ÄÜÖäüöß. ]*$/.test(e);
  });
  $.validator.addMethod("email", function (e, t) {
    return (
      this.optional(t) ||
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
        e
      )
    );
  });
  $.validator.addMethod("phone", function (e, t) {
    return (
      this.optional(t) ||
      /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/.test(e)
    );
  });
  window.mailform_validator = $("#mail-form").validate({
    rules: {
      recipient_email: { required: !0 },
      gender: { required: !0 },
      firstname: { required: !0, minlength: 2, maxlength: 50, textOnly: !0 },
      lastname: { required: !0, minlength: 2, maxlength: 50, textOnly: !0 },
      dataprotection: { required: !0 },
      email: { required: !0, email: !0, remote: "/mail.php" },
      phone: { required: !0, phone: !0, remote: "/phonenumber.php" },
      message: { required: !0, minlength: 1 },
    },
    messages: {
      gender: {
        required: "Wähle bitte ein Geschlecht aus",
      },
      firstname: {
        required: "Geben Sie bitte Ihren Vornamen an",
        textOnly: "Nutze bitte nur Buchstaben, keine Zahlen oder Sonderzeichen",
        minlength: "Geben Sie bitte mehr als 1 Zeichen an",
        maxlength: "Geben Sie bitte maximal 50 Zeichen an",
      },
      lastname: {
        required: "Geben Sie bitte Ihren Nachnamen an",
        textOnly: "Nutze bitte nur Buchstaben, keine Zahlen oder Sonderzeichen",
        minlength: "Geben Sie bitte mehr als 1 Zeichen an",
        maxlength: "Geben Sie bitte maximal 50 Zeichen an",
      },
      email: {
        required: "Geben Sie bitte Ihre E-Mail-Adresse an",
        email: "Geben Sie bitte eine gültige E-Mail-Adresse an",
        remote: "Geben Sie bitte eine gültige E-Mail-Adresse an",
      },
      recipient_email: {
        required: "Geben Sie bitte Ihre E-Mail-Adresse an",
        email: "Geben Sie bitte eine gültige E-Mail-Adresse an",
      },
      phone: {
        required: "Geben Sie bitte Ihre Telefonnummer an",
        number: "Geben Sie bitte eine gültige Telefonnummer an",
        phone: "Geben Sie bitte eine gültige Nummer an",
        remote: "Geben Sie bitte eine gültige Nummer an",
      },
      dataprotection: {
        required: "Stimme bitte der Datenschutzerklärung zu",
      },
      message: {
        required: "Geben Sie bitte Ihre Nachricht an",
        minlength: "Geben Sie bitte mehr als 1 Zeichen an",
      },
    },
    onsubmit: false,
  });

  window.mailpartnerform_validator = $("#mail-partner-form").validate({
    rules: {
      recipient_email: { required: !0 },
      interest: { required: !0 },
      firstname: { required: !0, minlength: 2, maxlength: 50, textOnly: !0 },
      lastname: { required: !0, minlength: 2, maxlength: 50, textOnly: !0 },
      dataprotection: { required: !0 },
      email: { required: !0, email: !0, remote: "/mail.php" },
      phone: { required: !0, phone: !0, remote: "/phonenumber.php" },
      message: { minlength: 1 },
    },
    messages: {
      interest: {
        required: "Wähle bitte ein Interesse aus",
      },
      firstname: {
        required: "Geben Sie bitte Ihren Vornamen an",
        textOnly: "Nutze bitte nur Buchstaben, keine Zahlen oder Sonderzeichen",
        minlength: "Geben Sie bitte mehr als 1 Zeichen an",
        maxlength: "Geben Sie bitte maximal 50 Zeichen an",
      },
      lastname: {
        required: "Geben Sie bitte Ihren Nachnamen an",
        textOnly: "Nutze bitte nur Buchstaben, keine Zahlen oder Sonderzeichen",
        minlength: "Geben Sie bitte mehr als 1 Zeichen an",
        maxlength: "Geben Sie bitte maximal 50 Zeichen an",
      },
      email: {
        required: "Geben Sie bitte Ihre E-Mail-Adresse an",
        email: "Geben Sie bitte eine gültige E-Mail-Adresse an",
        remote: "Geben Sie bitte eine gültige E-Mail-Adresse an",
      },
      recipient_email: {
        required: "Geben Sie bitte Ihre E-Mail-Adresse an",
        email: "Geben Sie bitte eine gültige E-Mail-Adresse an",
      },
      phone: {
        required: "Geben Sie bitte Ihre Telefonnummer an",
        number: "Geben Sie bitte eine gültige Telefonnummer an",
        phone: "Geben Sie bitte eine gültige Nummer an",
        remote: "Geben Sie bitte eine gültige Nummer an",
      },
      dataprotection: {
        required: "Stimme bitte der Datenschutzerklärung zu",
      },
      message: {
        minlength: "Geben Sie bitte mehr als 1 Zeichen an",
      },
    },
    onsubmit: false,
  });
});
